import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Team | RAK Engineering Ltd';

},[]);

  return (
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           <h2 className="home-h2 team-h2">Our Team</h2>
  <table>
    <tr>
      <th>S/No.</th>
      <th>Name</th>
      <th>Title</th>
      <th>Qualifications</th>
    </tr>

     <tr>
      <td>1.</td>
      <td>Rogers Kamuhanda</td>
      <td>Managing Director</td>
      <td>PMP (PMI), MBA (MUK), Bsc. Telecommunication Engineering (MUK), PPM (MUK), CRB (China), CTVB (China)</td>
    </tr>

      <tr>
      <td>2.</td>
      <td>Adrine Kirabo</td>
      <td>Finance & Admin Manager</td>
      <td>Msc. Env. Management (MUK), Bsc. Forestry (MUK)</td>
    </tr>

    <tr>
      <td>3.</td>
      <td>Cyrus Matsiko</td>
      <td>Accountant</td>
      <td>CPA, BBA (MUST)</td>
    </tr>

    <tr>
      <td>4.</td>
      <td>Andrew Sserunjoji</td>
      <td>Leads Projects Manager</td>
      <td>M. Public Infrastrusture Management (MUK), B. Construction Mgt (MUK)</td>
    </tr>

      <tr>
      <td>5.</td>
      <td>Wilson Mayende</td>
      <td>Project Manager</td>
      <td>HDC, Dip. Project Mgt, Cert. Network Civil Works, Cert. Fibre Installation</td>
    </tr>

    <tr>
      <td>6.</td>
      <td>Rogers K. Zimula</td>
      <td>Business Dev't Manager</td>
      <td>MBA (MUK), B. Commerce (MUK)</td>
    </tr>

     <tr>
      <td>7.</td>
      <td>Eng. Jjemba Edgar</td>
      <td>Senior Projects Engineer</td>
      <td>Registered Engineer - ERB No. 1065, Msc. Eng, Msc. Public Infrastrucure Mgt </td>
    </tr>

     <tr>
      <td>8.</td>
      <td>Buyinza Jonatdan</td>
      <td>Lead Survey Consultant</td>
      <td>Cert. Occupational Healtd & Safety, Bsc. Surveying</td>
    </tr>

    <tr>
      <td>9.</td>
      <td>Milly Okiria Kunihira</td>
      <td>Lead Survey Consultant</td>
      <td>B.A Social Work & Social Administration, Dip. Project Planning & Management</td>
    </tr>
  
  
  </table>


         </div>
    </section>
  )
}

export default Team