import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = 'Who We Are | SAECBO';

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">Who We Are</h2>
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                          <h3 className='title-h3'>Vision</h3>
                          <div className='vm-line'></div>
                          <p>To become the most iconic environmental conservation centric hands-on solutions organization in Uganda.</p>
                          
                          <h3 className='title-h3'>Mission</h3>
<div className='vm-line'></div>
                          <p>To efficiently and effectively deliver environmental conservation and carbon sequestration centered innovative hands-on solutions.</p>

                    </article>

                    


                 </div>

                 <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                       <MissionCarousel></MissionCarousel>
                    </article>

                    <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article>


                 </div>
          </div>
    </section>
     <section className='section-cover clients-cover' >
       <h3 className='title-h3'>Company Structure </h3>
<div className='vm-line'></div>
          {/* <h2 className="home-h2"></h2>
          <p className='clients-para'><strong></p> */}
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/39.jpg' alt='client' className='client-image'></img>
           </article>
          </div> 
    </section>

    </>
  )
}

export default WhoWeAre