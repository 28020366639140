import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import WhoWeAre from './pages/WhoWeAre';
import Team from './pages/Team';
import Services from './pages/Services';
import Clients from './pages/Clients';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Partners from "./pages/Partners";
import Error from './pages/Error';
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import TopSection from "./components/TopSection";
import WhatsAppIcon from "./components/WhatsAppIcon";
import BackToTop from "./components/BackToTop";

function App() {
  return (
    <BrowserRouter>
    <TopSection></TopSection>
      <Nav></Nav>
       <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='who-we-are' element={<WhoWeAre></WhoWeAre>}></Route>
            <Route path='team' element={<Team></Team>}></Route>
            <Route path='what-we-do' element={<Services></Services>}></Route>
            <Route path='partners' element={<Partners></Partners>}></Route>
            <Route path='gallery' element={<Gallery></Gallery>}></Route>
             <Route path='contact' element={<Contact></Contact>}></Route>
             <Route path='*' element={<Error></Error>}></Route>

       </Routes>
       <WhatsAppIcon></WhatsAppIcon><BackToTop></BackToTop>
       <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
