import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import Climate from '../components/Climate';


const Services = () => {

useEffect(()=>{
document.title = 'Services | SAECBO';

},[]);


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">What We Do</h2>
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <p className='title-h3 service-h3'>Protect and improve local environments and threatened tree species and deforested areas. This will be through education, distribution of tree seedlings to families, special restoration and demonstration areas and construction of fuel-efficient cook stoves </p>
                     {/* <ul>
                      <li>Communication Site Construction</li>
                      <li>Site and Way Leave Aquisition</li>
                      <li>Fibre Optic Line Construction</li>
                      <li>Telecoms Equipment Installation</li>
                     </ul> */}
                  </article>

                  <article className='service-slides'>
                    {/* <TelecomCarousel></TelecomCarousel> */}
                     <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <p className='title-h3 service-h3'>Work together as a Community Based Organization with community members, officers, volunteers, advisors, local leaders and Environmental Officers and National Environmental Management Authority</p>
                     
                  </article>

                  <article className='service-slides'>
                    {/* <PowerCarousel></PowerCarousel> */}
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <p className='title-h3 service-h3'>Contribute to mitigation of global climate change </p>
                
                  </article>

                  <article className='service-slides'>
                    {/* <RoadCarousel></RoadCarousel> */}
                 <Climate></Climate>
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <p className='title-h3 service-h3'>Increase community knowledge, practice and skills, for environmental conservation, tree planting and improving sources of firewood </p>
                     
                  </article>

                  <article className='service-slides'>
                    {/* <SupportCarousel></SupportCarousel> */}
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <p className='title-h3 service-h3'>Increase hope and optimism through community gatherings and participation in the project by volunteers in this locally operated volunteer organization</p>
                
                  </article>

                  <article className='service-slides'>
                    {/* <MaintCarousel></MaintCarousel> */}
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>


            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <p className='title-h3 service-h3'>Focus on areas of greatest need and poverty within Rubirizi District and neighbouring districts </p>
                     
                  </article>

                  <article className='service-slides'>
                    {/* <EmergencyCarousel></EmergencyCarousel> */}
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <p className='title-h3 service-h3'>Carrying out environmental research based on our projects</p>
                
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                    {/* <RoadCarousel></RoadCarousel> */}
                 
                  </article>     
            </div>




            
           </div>

    </section>
  )
}

export default Services