import React, {useEffect} from 'react';
import {FaArrowAltCircleRight} from "react-icons/fa"


const Services = () => {

useEffect(()=>{
document.title = 'Partners | SAECBO';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">Partners</h2> */}
           <div className='content-container services-container'>
           
               <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Partners</h2>
              <div className='goals-list partners-list'>
                     <div className='goal partner '>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description partner-p'>Seeker’s Church, Washington, D.C., USA</p>
                     </div>

                     <div className='goal partner'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description partner-p'>Government of Uganda</p>
                     </div>
                     <div className='goal partner'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description partner-p'>National Environmental Management Authority</p>
                     </div>

                    

                    

                </div>
                   

           </article>

               <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Advisors</h2>
              <div className='goals-list partners-list'>
                     <div className='goal partner '>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description partner-p'>Diantha Hodges - An environmental conservation advocate</p>
                     </div>

                </div>
                   

           </article>

           {/* <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article> */}




       </div>

  </section>



            
           </div>

    </section>
  )
}

export default Services