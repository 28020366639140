import React, {useEffect} from 'react'

const Gallery = () => {
  useEffect(()=>{
document.title = 'Gallery | SAECBO';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Our Gallery</h2>
      <div className='content-container gallery'>
        <article className='gallery-content'>
          <img src='./images/38.jpg' className='gallery-image' alt='image'></img>
           <p>Stove and environment education</p>
         </article>
         <article className='gallery-content'>
          <img src='./images/37.jpg' className='gallery-image' alt='image'></img>
           <p>Sergio in forest</p>
         </article>
        <article className='gallery-content'>
          <img src='./images/36.jpg' className='gallery-image' alt='image'></img>
           <p>Seedlings packed in nursery</p>
         </article>
        <article className='gallery-content'>
          <img src='./images/35.jpg' className='gallery-image' alt='image'></img>
           <p>Sergio teaching at Church</p>
         </article>
        <article className='gallery-content'>
          <img src='./images/34.jpg' className='gallery-image' alt='image'></img>
           <p>Sergio looking over land</p>
         </article>
        <article className='gallery-content'>
          <img src='./images/33.jpg' className='gallery-image' alt='image'></img>
           <p>Sergio and Diantha Hodges meet</p>
         </article>
        <article className='gallery-content'>
          <img src='./images/32.jpg' className='gallery-image' alt='image'></img>
           <p>Nursery under palm leaves</p>
         </article>
        <article className='gallery-content'>
          <img src='./images/31.jpg' className='gallery-image' alt='image'></img>
           <p>Mount Elgon Firewood Harvesting</p>
         </article>
         <article className='gallery-content'>
          <img src='./images/30.jpg' className='gallery-image' alt='image'></img>
           <p>Forest and Crops</p>
         </article>
        <article className='gallery-content'>
          <img src='./images/29.jpg' className='gallery-image' alt='image'></img>
           <p>Tree seedling distribution at Church</p>
         </article>
        <article className='gallery-content'>
          <img src='./images/28.jpg' className='gallery-image' alt='image'></img>
           <p>Bag of seeds in brown pods</p>
         </article>
        <article className='gallery-content'>
          <img src='./images/27.jpg' className='gallery-image' alt='image'></img>
           <p>2022 Seedlings</p>
         </article>
        <article className='gallery-content'>
          <img src='./images/26.jpg' className='gallery-image' alt='image'></img>
           <p>2022 Potting</p>
         </article>
        <article className='gallery-content'>
          <img src='./images/25.jpg' className='gallery-image' alt='image'></img>
           <p>2022 Nursery Bed</p>
         </article>
        {/* <article className='gallery-content'>
          <img src='./images/24.jpg' className='gallery-image' alt='image'></img>
           <p>2022 Community Leaders</p>
         </article> */}
         <article className='gallery-content'>
          <img src='./images/5.jpg' className='gallery-image' alt='image'></img>
           <p>Community training on environmental protection by our team of experts</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/7.jpg' className='gallery-image' alt='image'></img>
           <p>Construction of a wood stove at a home of one of the Save Ankole's Environment Wood Stove Project beneficiaries</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/12.jpg' className='gallery-image' alt='image'></img>
           <p>Seeds of different tree species that were collected from the forest germinated well and some are ready for potting</p>
         </article>

           <article className='gallery-content'>
          <img src='./images/23.jpg' className='gallery-image' alt='image'></img>
           <p>Tree planting awareness in Nyamirima Katerera Rubirizi district</p>
         </article>
        

        



      </div>

    </section>
  )
}

export default Gallery