import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare, FaTwitterSquare, FaLinkedin} from 'react-icons/fa';

const Footer = () => {
  return (
    <section className='section-cover footer-cover'>
      <div className='content-container footer-content'>
             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                        <div className='footer-link'>
                             <Link to='/'>  <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Home</p></Link> 
                        </div>

                         <div className='footer-link'>
                              <Link to='who-we-are'> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Who We Are</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='#'> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Team</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='what-we-do'> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>What We Do</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='partners'> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Partners</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='gallery'>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Gallery</p></Link> 
                        </div>
                      
                      
                    </div>

             </div>

             <div className='footer-inner-content'>
                    <div className='footer-location'>
                      <h3>We're Located At</h3>
                      <p>Rubirizi, Uganda</p>

                    </div>

                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>+256701-122438 <br></br>+256788-557266</p>

                    </div>

             </div>

             <div className='footer-inner-content'>
                     <div className='footer-email'>
                      <h3>Write To Us At</h3>
                      <p>ignsleo@gmail.com<br></br>info@saecbo.org</p>
                      
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                               <a href="https://www.facebook.com/saveAnkolesEnvironmentCommunityBasedOrganisation" target="_blank"> <span>
<FaFacebookSquare></FaFacebookSquare>
                                 </span></a>
                                 <span>
<FaTwitterSquare></FaTwitterSquare>
                                 </span>

                                 <span>
<FaLinkedin></FaLinkedin>
                                 </span>  
                         </div>
                        
                        
                      </div>

             </div>

        
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> SAECBO | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer