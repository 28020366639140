import React, { useRef, useState,useEffect } from 'react';
import emailjs from '@emailjs/browser';
import ContactCarousel from '../components/ContactCarousel';
const Contact = () => {
   let [successMessage, setSuccessMessage] = useState(false);
        let [errorMessage, setErrorMessage] = useState(false);
        let [sendingMessage, setSendingMessage] = useState(false);
         let formInput1  = useRef(null);
        let formInput2  = useRef(null);
        let formInput3  = useRef(null);
        let formInput4  = useRef(null);
        let formInput5  = useRef(null);

let sendFunc =()=>{
  setSendingMessage(true);
    setSuccessMessage(false);
    setErrorMessage(false);
  
}
  useEffect(()=>{
document.title = 'Contact Us | SAECBO';

},[]);

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0gezqkk', 'template_bvd501m', form.current, 'NR4-kxxmDYKuZrgfR')
      .then((result) => {
          // console.log(result.text);
          // console.log('Message Sent');
           if(result){
setSuccessMessage(true);
          setSendingMessage(false);

              setTimeout(() => {
            setSuccessMessage(false);
        }, 3000);

         }
  formInput1.current.value = '';
    formInput2.current.value = '';
    formInput3.current.value = '';
    formInput4.current.value = '';
    formInput5.current.value = '';

      }, (error) => {
          // console.log(error.text);
            if (error){
setErrorMessage(true);
          setSendingMessage(false);
           setTimeout(() => {
           setErrorMessage(false);
        }, 3000);

          }
  formInput1.current.value = '';
    formInput2.current.value = '';
    formInput3.current.value = '';
    formInput4.current.value = '';
    formInput5.current.value = '';
      });
  };
  return (
    <section className='section-cover contact-cover' >
      <h2 className="home-h2 ">Write To Us</h2>
         <div className='content-container contact-section'>

<div className='form-cover'>
  <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" ref={formInput1} />
      <label>Email</label>
      <input type="email" name="user_email" ref={formInput2}/>
      <label>Contact Number</label>
      <input type="text" name="user_number" ref={formInput3} />
      <label>Subject</label>
      <input type="text" name="user_subject" ref={formInput4}/>


      <label>Message</label>
      <textarea name="message" ref={formInput5}/>
       <div  className={`${sendingMessage?'success-box':'hide'}`}><p>Sending...</p></div>
      <div  className={`${successMessage?'success-box':'hide'}`}><p>Thank you! Your message has been sent.</p></div>
      <div className={`${errorMessage?'error-box':'hide'}`}><p>Oh, sorry! An error occurred.</p></div>
      <input type="submit" value="Send" onClick={sendFunc} />
    </form>
</div>
           <div className='contact-slides'> 
{/* <ContactCarousel></ContactCarousel> */}
<h3 className='title-h3'>Map of Rubirizi district</h3>
<img src='images/40.png'></img>
</div>

         </div>

    </section>
  )
}

export default Contact