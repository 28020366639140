import React,{useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaFileAlt,FaCubes,FaThumbsUp,FaCheck,FaTools, FaQuoteLeft,FaArrowAltCircleRight} from 'react-icons/fa';
const Home = () => {
  useEffect(()=>{
document.title = 'SAECBO | Together we can improve our environment for our communities and the earth';

},[]);
  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container'>
          <h2 className="home-h2">Background</h2>
          <div className='home-line'></div>

                 <p className='home-p'><strong>Save Ankole’s Environment Community Based Organization (SAECBO)</strong> is an organization based in western Uganda whose aim is providing environmental solutions. SAECBO was established in March 2020. We efficiently and effectively deliver environmental conservation centered innovative hands-on solutions.</p>
<p className='home-p'>With our highly experienced human resource and resources, we ensure that every project is completed on time and with no bottlenecks offering quality output.</p>


        </div>

    </section>
     <section className='home-quote' style={{ backgroundImage: `linear-gradient(  #1833289a, #183328d1), url("./images/1.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Slogan</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>Together we can improve our environment for our communities and the earth.</p>
        </div>
    </section>
     <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Services</h2>
              <div className='goals-list'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Joining efforts with our Community Based Organization, local political and community leaders.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Educating people about the importance of environmental conservation, for agroforestry carbon sequestration, and the important roles of trees. </p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description project-3'>Addressing the localities’ demands and sources for firewood by investigation, education and planting trees to be used for firewood.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Providing education and construction of fuel efficient basement wood burning cook stoves, and training a core of local volunteers in construction.</p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Prioritizing the endangered tree species, fruit, food and trees for improved livelihoods and medicine trees and other trees for agroforestry.     </p>
                     </div>
                       <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Using sound conservation and agroforestry practices.</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Planting large number of trees through demonstration, conservation projects and providing tree seedlings to the population and guiding them on how to plant and care for them.</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Monitoring trees and community engagement for successful growth of trees.</p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Carrying out land restoration or conservation project of degraded or threatened land when possible.</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Carrying out environmental conservation related research and sharing feedback with targeted groups i.e. communities, students in learning institutions, leaders and Government and environmental conservation bodies and donors.</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Involving the community throughout all activities. Relying heavily on community volunteers to carry out projects. Including training a core of local volunteers in how to sustainably harvest the seeds and young seedlings from wild areas, to build and care for nursery bed, to help educate people and distribute seedlings, and to monitor the seedlings as they grow.</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Building a relationship with and educating donors and partners.</p>
                     </div>

                </div>
                   

           </article>

           {/* <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article> */}




       </div>

  </section>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(  #1833289a, #183328d1), url("./images/1.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We pledge to promote environmental conservation projects in a unique way while creating extraordinary solutions that make the complex simple.</p>
        </div>
    </section>

    <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Our Value Proposition</h2>
   
    <div className='home-services-container '>
      {/* <div className='about-img-container'>
      <img src='./images/AWUKO1.jpg' alt='BENARD AWUKO' className='about-img'></img>
   </div> */}
       <div className='home-services-data'>
       <div className='home-services-info '>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Innovative Hands-on Solutions</h3>
          <p>At SAE, we back on our ingenuity to make the complex simple. We use soft and doable approaches to meet our environmental conservation goals.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaThumbsUp></FaThumbsUp></span>
          </div>
          <h3 className='home-service-title'>Efficiency & Effectiveness</h3>
          <p> With our highly experienced human resource and other resources, we ensure that every project is completed on time and with no bottlenecks offering quality output.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality Awareness</h3>
          <p>While working together, SAE offers quality tree species, woodstoves and environmental conservation education to the people through our well experienced human resource, volunteers and partnerships.</p>
        </div>




       

        
    
    </div>
        <Link to="services" className='home-btn'>
         What We Do
         </Link>


        </div>


    </div>



  </section>
  <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Completed Projects</h2>
              <div className='goals-list'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Distribution of 50,687 tree seedlings for planting in Rubirizi district in 2020 and 2021. </p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Distribution of 18,457  tree seedlings for planting in Rubirizi district to 906 families in 2022. </p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description project-3'>Plantings on individual homesteads.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Environmental conservation awareness seminars in Rubirizi and Rwampara districts (Katerera sub county. Kirugu sub county, Mwongyera sub county, Nyakishi sub county). </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Construction of 147 fuel efficient wood cookstoves in Rubirizi district (including 39 in 2022). </p>
                     </div>
                       <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Numerous environmental conservation sensitization and education in the area. </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Special outreach for those who are most remote and impoverished.</p>
                     </div>

                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>




       </div>

  </section>
    
  
    </>
  )
}

export default Home