import React from 'react'

let ValuesData = [

{title: "Ingenuity",      
content: "We approach every environmental conservation project in a unique way creating extraordinary solutions that make the complex simple."
},

{title: "Integrity ",      
content: "We are honest and transparent in everything we do."
},


{title: "Efficiency",      
content: "We ensure that resources are optimised to the best while providing environmental conservation hands-on solutions."
},

{title: "Effectiveness",      
content: "Our partners are guaranteed a timely delivery of our proposed/planned projects."
}


];

export default ValuesData;